import { render, staticRenderFns } from "./participant-manage-demographic-data-action.vue?vue&type=template&id=873b7de8"
import script from "./participant-manage-demographic-data-action.ts?vue&type=script&lang=js&external"
export * from "./participant-manage-demographic-data-action.ts?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports