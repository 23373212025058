import { render, staticRenderFns } from "./api-keys-overview.vue?vue&type=template&id=f0f9f8de"
import script from "./api-keys-overview.ts?vue&type=script&lang=js&external"
export * from "./api-keys-overview.ts?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports