import { render, staticRenderFns } from "./titleBar.vue?vue&type=template&id=3e35d371&scoped=true"
import script from "./titleBar.ts?vue&type=script&lang=js&external"
export * from "./titleBar.ts?vue&type=script&lang=js&external"
import style0 from "./titleBar.vue?vue&type=style&index=0&id=3e35d371&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e35d371",
  null
  
)

export default component.exports