import { Component } from 'vue-property-decorator';
import { GridColumnProps, GridPageChangeEvent } from '@progress/kendo-vue-grid';
import { PagedResponse } from '@/models/PagedResponse';
import BasePage from '@/models/BasePage';
import Vue from 'vue';
import Account from '@/modules/settings/models/Account';
import AccountFilter from '@/modules/settings/models/AccountFilter';
import { PartnerAccountsService } from '../services/partnerAccountsService';
import areYouSureModalVue from '@/components/modals/are-you-sure-modal.vue';
import to from 'await-to-js';

@Component
export default class AccountsOverviewComponent extends BasePage {
    public accounts: Account[] = [];
    public partnerAccountService: PartnerAccountsService = new PartnerAccountsService();
    public partnerId: number = null;
    public isLoaded: boolean = false;
    public selectedAccount?: Account = null;
    public $refs!: {
        areYouSureModal: areYouSureModalVue;
    };

    public columns: GridColumnProps[] = [
        { field: 'userId', title: 'Id', width: 100 },
        { field: 'name', title: 'name', cell: this.renderName },
        { field: 'emailAddress', title: 'Email address' },
        // { field: 'enabled', title: 'Enabled', cell: this.renderBoolean, width: 100 },
        { field: 'actions', title: 'Actions', cell: this.renderActions, width: 100 },
    ];

    public accountsData: any = {
        accounts: [],
        count: 0,
        skip: 0,
        take: 25,
        search: '',
    };
    private filterTimer: number = null;

    public async created() {
        this.partnerId = parseInt(this.$route.params.partnerId);
        await this.getAccounts();

        this.isLoaded = true;
    }

    public filterChanged(): void {
        if (this.filterTimer) {
            clearTimeout(this.filterTimer);
        }

        this.filterTimer = window.setTimeout(async () => {
            this.accountsData.skip = 0;
            await this.getAccounts();
        }, 400);
    }

    public pageChangeHandler(event: GridPageChangeEvent): void {
        this.accountsData.skip = event.page.skip;
        this.accountsData.take = event.page.take;
        // tslint:disable-next-line: no-floating-promises
        this.getAccounts();
    }

    public renderName(h, _, row) {
        return h('td', {}, [`${row.dataItem.firstName ?? '(First name not set)'} ${row.dataItem.lastName ?? '(Last name not set)'}`]);
    }

    public renderRoles(h, _, row) {
        return h('td', {}, [row.dataItem.roles.map((x) => x.roleName).join(', ')]);
    }

    private async getAccounts(includeCount: boolean = true) {
        await this.partnerAccountService
            .getAccounts(
                this.partnerId,
                new AccountFilter({
                    $count: includeCount,
                    take: this.accountsData.take,
                    skip: this.accountsData.skip,
                    search: this.accountsData.search,
                    orderBy: 'userName',
                }),
            )
            .then((value: Account[]) => {
                this.accountsData.accounts = value
                    .map((p) => {
                        return p;
                    })
                    .filter((p) => {
                        return p !== undefined;
                    });

                if (includeCount) {
                    this.accountsData.count = value.length;
                }

                this.isLoaded = true;
            });
    }

    public openAddAccountPartnerAction() {
        this.$sideActions.push('add-partner-account-action', { partnerId: this.partnerId }, async () => {
            await this.getAccounts();
        });
    }

    private showAreYouSureModal(account: Account) {
        this.selectedAccount = account;
        this.$refs.areYouSureModal.setScope(
            [account],
            'emailAddress',
            'Account',
            true,
            'Are you sure you want to delete this account? This action cannot be reverted.',
        );
        this.$refs.areYouSureModal.show();
    }

    private async removeAccount() {
        this.$refs.areYouSureModal.hide();
        this.showPending('Attempting to remove account');
        const [err] = await to(this.partnerAccountService.removeAccount(this.partnerId, this.selectedAccount.userId));
        if (err) {
            return this.showError('Failed to remove account');
        }
        return this.showSuccess('Account got removed');
    }

    private renderActions(h, _, row) {
        const actions = [];

        actions.push({
            title: 'Remove account',
            function: () => {
                this.showAreYouSureModal(row.dataItem);
            },
        });

        const props = { actions, item: row.dataItem };
        return h(Vue.component('grid-actions'), { props });
    }
}
