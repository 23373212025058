import { render, staticRenderFns } from "./grid-popover.vue?vue&type=template&id=193c89c8"
import script from "./grid-popover.ts?vue&type=script&lang=js&external"
export * from "./grid-popover.ts?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports