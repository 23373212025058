import OrderBy, { OrderByDirection } from '@/models/OrderAndFilter/OrderBy';
import { OrderByField } from '@/models/OrderAndFilter/OrderByField';

export default class ProjectOrderBy extends OrderBy {
    public fields = [
        new OrderByField({
            key: 'startDate',
            field: 'ProjectCreateDate',
            direction: OrderByDirection.None,
        }),
        new OrderByField({
            key: 'closeDate',
            field: 'CloseDate',
            direction: OrderByDirection.None,
        }),
        new OrderByField({
            key: 'ProjectName',
            field: 'ProjectName',
            direction: OrderByDirection.None,
        }),
    ];
}
