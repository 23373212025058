import { render, staticRenderFns } from "./grid-is-locked.vue?vue&type=template&id=2ac6a836"
import script from "./grid-is-locked.ts?vue&type=script&lang=js&external"
export * from "./grid-is-locked.ts?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports