import { Component } from 'vue-property-decorator';
import { GridColumnProps, GridPageChangeEvent } from '@progress/kendo-vue-grid';
import { PagedResponse } from '@/models/PagedResponse';
import BasePage from '@/models/BasePage';
import Account from '../models/Account';
import { AccountsService } from '../services/accountsService';
import AccountFilter from '../models/AccountFilter';
import Vue from 'vue';
import { to } from 'await-to-js';

@Component
export default class AccountsOverviewComponent extends BasePage {
    public accounts: Account[] = [];
    public accountService: AccountsService = new AccountsService();

    public isLoaded: boolean = false;
    public gridKey: string = new Date().getTime().toString();

    public columns: GridColumnProps[] = [
        { field: 'name', title: 'name', cell: this.renderName },
        { field: 'emailAddress', title: 'Email address' },
        { field: 'mfaEnabled', title: '2FA', width: 100, cell: this.renderMfaEnabled },
        { field: 'locked', title: 'Locked', cell: this.renderLocked, width: 100 },
        { field: 'lockoutDateUtc', title: 'Locked date', cell: this.renderDateLocked, width: 200 },
        { field: 'roles', title: 'Roles', cell: this.renderRoles },
        { field: 'roles', title: 'Related entity', cell: this.renderEntity },
        { field: 'actions', title: 'Actions', cell: this.renderActions, width: '100px' },
    ];

    public accountsData: any = {
        accounts: [],
        count: 0,
        skip: 0,
        take: 25,
        search: '',
    };
    private filterTimer: number = null;

    public async created() {
        await this.getAccounts();

        this.isLoaded = true;
    }

    public filterChanged(): void {
        if (this.filterTimer) {
            clearTimeout(this.filterTimer);
        }

        this.filterTimer = window.setTimeout(async () => {
            this.accountsData.skip = 0;
            await this.getAccounts();
        }, 400);
    }

    public pageChangeHandler(event: GridPageChangeEvent): void {
        this.accountsData.skip = event.page.skip;
        this.accountsData.take = event.page.take;
        // tslint:disable-next-line: no-floating-promises
        this.getAccounts();
    }

    public renderLockedAccounts(_h, tr, _row, item) {
        tr.data.class += item.dataItem.locked ? ' bg-secondary' : '';
        return tr;
    }

    public renderDateLocked(item: any, _, row: any): any {
        return item(Vue.component('grid-date-display'), { props: { date: row.dataItem.lockoutDateUtc, showTime: true } });
    }

    public renderName(h, _, row) {
        return h('td', {}, [`${row.dataItem.firstName ?? '(First name not set)'} ${row.dataItem.lastName ?? '(Last name not set)'}`]);
    }

    public renderRoles(h, _, row) {
        return h('td', {}, [row.dataItem.roles.map((x) => x.roleName).join(', ')]);
    }

    public renderLocked(h, _, row) {
        return h('td', {}, [row.dataItem.locked ? 'Yes' : 'No']);
    }

    public renderLockedDate(h, _, row) {
        return h('td', {}, [row.dataItem.lockedDate ? row.dataItem.lockedDate : '']);
    }

    public renderMfaEnabled(h, _, row) {
        return h(Vue.component('grid-enabled'), { props: { enabled: row.dataItem.twoFactorEnabled } });
    }

    public renderEntity(h, _, row) {
        let entityId = row.dataItem.claims.find((x) => x.claimType === 'PartnerId')?.claimValue;

        if (entityId) {
            return h(Vue.component('grid-router-link'), {
                props: {
                    title: `Partner ${entityId}`,
                    url: this.$router.resolve({
                        name: 'partner-details',
                        params: { partnerId: entityId.toString() },
                    }).href,
                },
            });
        }

        entityId = row.dataItem.claims.find((x) => x.claimType === 'PractitionerId')?.claimValue;

        if (entityId) {
            return h(Vue.component('grid-router-link'), {
                props: {
                    title: `Practitioner ${entityId}`,
                    url: this.$router.resolve({
                        name: 'practitioner-details',
                        params: { practitionerId: entityId.toString() },
                    }).href,
                },
            });
        }

        return h('td');
    }

    private async getAccounts(includeCount: boolean = true) {
        this.isLoaded = false;

        await this.accountService
            .getAccounts(
                new AccountFilter({
                    $count: includeCount,
                    take: this.accountsData.take,
                    skip: this.accountsData.skip,
                    search: this.accountsData.search,
                    orderBy: 'userName'
                }),
            )
            .then((value: PagedResponse<Account>) => {
                this.accountsData.accounts = value.items
                    .map((p) => {
                        return p;
                    })
                    .filter((p) => {
                        return p !== undefined;
                    });

                if (includeCount) {
                    this.accountsData.count = value.count;
                }

                this.gridKey = new Date().getTime().toString();
                this.isLoaded = true;
            });
    }

    private async unlockAccount(account: Account) {
        this.showPending('Unlocking account...');
        const [err] = await to(this.accountService.unlockAccount(account.userId));
        if (err) {
            return this.clearAndShowError('Failed to unlock account');
        }

        await this.getAccounts();
        this.clearAndShowSuccess('Account unlocked');
    }

    private openEditLanguagesSideAction(userItem: Account) {
        this.$sideActions.push('edit-language-permissions-action', { userId: userItem.userId }, async () => {
            await this.getAccounts();
        });
    }

    private async removePowerUserRole(account: Account) {
        this.showPending('Updating role...');
        const [err] = await to(this.accountService.removeUserRole(account.userId, 'PowerUser'));
        if (err) {
            return this.clearAndShowError('Failed to update role');
        }

        await this.getAccounts();
        this.clearAndShowSuccess('Role updated');
    }

    private async addPowerUserRole(account: Account) {
        this.showPending('Updating role...');
        const [err] = await to(this.accountService.addUserRole(account.userId, 'PowerUser'));
        if (err) {
            return this.clearAndShowError('Failed to update role');
        }

        await this.getAccounts();
        this.clearAndShowSuccess('Role updated');
    }

    private renderActions(h, _, row) {
        const actions = [];

        if (row.dataItem.locked) {
            actions.push({
                title: 'Unlock account',
                function: async () => {
                    await this.unlockAccount(row.dataItem);
                },
            });
        } else if (row.dataItem.roles && row.dataItem.roles.some((x) => x.roleName === 'PowerUser')) {
            actions.push({
                title: 'Remove PowerUser role',
                function: async () => {
                    await this.removePowerUserRole(row.dataItem);
                },
            });
        } else if (row.dataItem.roles && row.dataItem.roles.every((x) => x.roleName !== 'PowerUser')) {
            actions.push({
                title: 'Add PowerUser role',
                function: async () => {
                    await this.addPowerUserRole(row.dataItem);
                },
            });
        } else {
            return h('td');
        }

        const props = { actions, item: row.dataItem };
        return h(Vue.component('grid-actions'), { props });
    }
}
